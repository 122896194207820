/* Main container */
.homepage-container {
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  background-color: rgba(182, 188, 196, 1);
  overflow: auto;
  min-height: 100vh;
}

/* Background video wrapper */
.background-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0; /* This places the background video behind everything */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  background-size: cover;
  z-index: 0; /* Make sure background video stays in the background */
}

/* Dark overlay */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
  z-index: 1; /* Keep it above the video but below the content */
}

/* Content Area */
.content-area {
  position: relative;
  z-index: 1; /* Ensure all content stays above the background video */
  color: white;
  min-height: 100%;
}

/* Content wrapper */
.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

/* Text and Code Block */
.hero-text {
  z-index: 2; /* Text block stays above background */
  width: 50%;
  padding-left: 20px;
  margin-right: 40px;
}

.hero-text h1 {
  font-size: 3.5em; /* Increase this value to make the font larger */
  font-weight: bold;
  line-height: 1.2; /* Adjust line height if necessary */
}

.hero-text p {
  font-size: 1.5em; /* Increase this value to make the font larger */
  font-weight: bold;
  line-height: 1.2; /* Adjust line height if necessary */
}

.hero-code {
  width: 30%;
  height: 200px;
  overflow: auto;
  border-radius: 5px;
  position: relative;
  background-color: #2b2b2b;
  margin-left: 20px;
  z-index: 2; /* Code block stays above background */
}

/* Video Section */
.hero-video {
  margin-top: 30px;
  text-align: center;
  z-index: 2; /* Make sure the video section stays above the background */
}

.hero-video video {
  max-width: 400px;
  max-height: 400px;
  height: auto;
}

/* Styling for various elements */
.intro-bold {
  font-weight: bold;
  font-size: 1.5em;
}

.intro-light-green {
  color: lightgreen;
}

.intro-light-purple {
  color: #f068f0;
}

/* Window controls */
.window-controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 3; /* Ensure window controls are visible */
}

.window-controls span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.red-dot {
  background-color: #ff5f56;
}

.yellow-dot {
  background-color: #ffbd2e;
}

.green-dot {
  background-color: #27c93f;
}
