/* General container styles */
.account-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
.account-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

/* List styles */
.account-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.account-container li {
    margin-bottom: 15px;
    font-size: 16px;
    color: #555;
    display: block; /* Ensure each list item is a block element */
}

/* Label and value in the same line */
.account-container .label {
    font-weight: bold;
    margin-right: 5px; /* Add space between label and value */
    display: inline; /* Keep label inline */
}

.account-container .value {
    display: inline; /* Keep value inline */
}

/* RTL-specific list styles */
.rtl .account-container li {
    text-align: right; /* Align text to the right for RTL */
}

.rtl .account-container .label {
    margin-left: 5px; /* Reverse margin for RTL */
    margin-right: 0;
}

/* Button styles */
.account-container button {
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.account-container button:hover {
    background-color: #0056b3;
}
