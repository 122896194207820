.compiler-page {
  background-image: url('../assets/practice_background.jpg'); /* Update the path accordingly */
  background-size: contain;
  background-position: right center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh; /* Full viewport height */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #000;
} 

/* General container styles */
.editor-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #ffffff;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
}

.monaco-editor {
  direction: ltr !important; /* Force the Monaco editor to be left-to-right */
}
/* Control panel styles */
.control-panel {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Increased margin between control panel and editor */
}

/* Theme toggle button styles */
.theme-toggle-button {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  margin-right: 15px;
}

.theme-toggle-button.light {
  background-color: #f5f5f5;
  color: #000;
}

/* Font size dropdown styles */
.font-size-select {
  padding: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button styles */
.run-button, .submit-button {
  background-color: #7209b7;
  color: white;
  padding: 0.75rem 2rem; /* Keep consistent padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 10px;
  width: 150px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  display: inline-flex; /* Ensure content doesn't change the button's size */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.run-button {
  background-color: #09b70f;
}

/* Loading spinner styles */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

.run-button:disabled, .submit-button:disabled {
  width: 150px; /* Same width */
  height: 50px; /* Same height */
  cursor: not-allowed;
  opacity: 0.6;
}

/* Keyframe animation for spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Disabled button cursor style */
.submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optional: make the button look disabled */
}

.run-button:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Optional: make the button look disabled */
}

/* Output box container */
.output-box-container {
  width: 80%;  /* Increase the width */
  max-width: 800px; /* Set a max width for large screens */
  margin-top: 2rem;
  position: relative;
}

/* Title container with dynamic alignment based on language direction */
.output-box-title {
  margin-bottom: 0.5rem;
  text-align: center; /* Default for LTR */
}

.rtl .output-box-title {
  text-align: center; /* Keep it centered even for RTL */
}

/* Output box */
.output-box-code {
  height: 200px; /* Increase height to make it larger */
  max-height: 300px; /* Set a max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem;
  background-color: #1e1e1e;
  color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  white-space: pre-wrap; /* Ensure line breaks are respected */
  text-align: left; /* Ensure text is always left-aligned in the box */
  direction: ltr !important;
}

.close-output-btn {
  position: absolute;
  right: 0; 
  background-color: transparent;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 10px;
  line-height: 1.5;
}

.close-output-btn:hover {
  color: yellow;
}

/* Add scrollbar styling if needed */
.output-box-code::-webkit-scrollbar {
  width: 8px;
}

.output-box-code::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.output-box-code::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.useless-functions-container {
  background-color: #e6f7ff; /* Soft blue background */
  border: 5px solid #91d5ff; /* Light blue border */
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px; /* Space above the box */
  margin: 20px auto 0 auto; /* Center the box horizontally */  
  width: 50%;                
  text-align: start;
}

.useless-functions-text {
  margin: 10px 0;
  font-size: 16px;
  color: #000; /* Black text */
}

.file-upload-button {
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #333;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-upload-button i {
  font-size: 16px;
}