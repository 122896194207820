/* src/App.css */

body {
  font-family: Arial, sans-serif;
  background-color: #0e0e0e;
  color: white;
  margin: 0;
  padding: 0;
}

/* Heading styles */
h2 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #121212;
  position: sticky;
  top: 0;
}

.navbar ul {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.navbar li {
  cursor: pointer;
}

.member-btn {
  background-color: #7209b7;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: #1a1a1a;
}

.hero-text {
  max-width: 50%;
}

.hero h1 {
  color: white;
  font-size: 2.5rem;
}

.cta-button {
  background-color: #7209b7;
  color: white;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.hero-code {
  background-color: #252525;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.introduction {
  padding: 2rem;
  background-color: #1a1a1a;
  text-align: center;
  direction: rtl;
}

.introduction h2 {
  color: #d4af37;
  font-size: 3rem;
}

.introduction p {
  font-size: 2rem;
  color: #ccc;
  margin-top: 1rem;
}

/* Add to your App.css or create a separate CSS file */

.compiler-page {
  padding: 2rem;
  text-align: left;
}

.code-editor {
  width: 50%;
  height: 300px;
  padding: 1rem;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #c6c4c4;
  border-radius: 5px;
}

.button-group {
  display: flex;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 1rem;
}