/* src/SignInSignUp.css */

/* Container Styles */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: black;
  }
  
  /* Form Styles */
  .auth-form {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 400px;
    width: 100%;
  }
  
  .auth-form h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.8rem;
  }
  
  .auth-form input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .auth-form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #0056b3;
  }
  
  /* Error Message Styles */
  .auth-form p {
    color: red;
    font-size: 0.9rem;
    text-align: center;
  }
  
  /* Success Message Styles */
  .auth-form .success-message {
    color: green;
    font-size: 0.9rem;
    text-align: center;
  }
  .auth-form p {
    font-size: 14px;
    color: #333;
  }
  
  .auth-form .error-message {
    color: red;
    margin-bottom: 10px;
  }