.contact-container {
  background-image: url('../assets/contact_background.jpg'); /* Update the path accordingly */
  background-size: contain;
  background-position: right center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  min-height: 100vh; 
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: #000;

  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content higher up */
  align-items: center;
  height: 60vh; /* Full viewport height */
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 28px;
  padding-top: 100px; /* Add some padding to move it down a little */
}

.contact-container p {
  margin: 0;
  padding: 10px;
}

.contact-container a {
  color: #007bff;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}

.email-link {
  display: inline; /* Ensure the link stays on the same line */
}
