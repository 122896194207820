/* Navbar container */
.navbar {
  background-color: #333;
  font-family: Arial;
  z-index: 9999; /* Ensure the navbar is always on top */
  position: relative; /* Needed for z-index to work */
  display: flex; /* Use Flexbox to align items */
  justify-content: space-between; /* Separate left and right sections */
  align-items: center; /* Vertically center all items */
  padding: 0 20px; /* Add padding to the left and right */
}

/* Navbar list */
.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center; /* Vertically center the items in the list */
}

/* Navbar list items */
.navbar li {
  margin-right: 20px; /* Space between items */
  display: flex;
  align-items: center; /* Vertically center individual list items */
}

/* Links inside the navbar */
.navbar a, .navbar button {
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  border: none;
  background: none; /* Remove background from links */
  display: flex;
  align-items: center; /* Vertically center content inside links and buttons */
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: visible; /* Allow the dropdown content to be visible */
  position: relative;
  z-index: 1; /* Ensure dropdown button stays visible */
  display: flex;
  align-items: center; /* Vertically center the dropdown button */
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  display: flex;
  align-items: center; /* Vertically center the dropdown button */
}

/* Add a background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn, .navbar button:hover {
  background-color: #7209b7;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999; /* Ensure the dropdown content is in front of all other elements */
  top: 80%; /* Position the dropdown just below the button */
  left: 0;   /* Align it with the dropdown button */
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; /* Display the dropdown when hovering */
}

/* Button container for Sign Out */
.signout-container {
  margin-left: auto; /* Push the Sign Out button to the far right */
}

/* Button styles */
.navbar button {
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar button:hover {
  background-color: #0056b3;
}

.language-switcher {
  margin-left: auto;
}

.language-switcher select {
  padding: 5px;
  font-size: 14px;
}