/* New table container styles */
.table-container {
    width: 90%; /* Control the overall width of the table */
    max-width: 1200px; /* Optional: set a max-width */
    margin: 0 auto; /* Center the table with horizontal margins */
    padding: 20px; /* Add padding around the table */
  }
  
table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

table th, table td {
    font-size: 18px; /* Increase font size */
    padding: 12px; /* Increase padding */
    text-align: center; /* Center-align text */
    border: 2px solid #000000;
}


@media (max-width: 768px) {
    table th, table td {
        font-size: 16px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    table th, table td {
        font-size: 14px;
        padding: 6px;
    }
}

table th:first-child, table td:first-child {
    width: 40%; /* Make the first column (Function Name) take up 20% of the table width */
}

table th:not(:first-child), table td:not(:first-child) {
    width: 10%; /* Make other columns take up 10% each */
}

/* Header background */
.table-header {
    background-color: teal;
}

/* Alternating row colors */
.table-row:nth-child(even) {
    background-color: rgb(109, 120, 234);
    color: black
}

.table-row:nth-child(odd) {
    background-color: rgb(183, 174, 234);
    color: black    
}

/* Status-based cell colors */
.status-error {
    background-color: darkgray;
    color: white;
}

.status-fail {
    background-color: red;
    color: white;
}

.status-pass {
    background-color: green;
    color: white;
}

.status-no-test {
    background-color: yellow;
}
