.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Full viewport height */
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .about-image {
    width: 300px;  /* Adjust the width to make the image bigger */
    height: auto;  /* Maintain the aspect ratio */
    margin-top: 20px;
    margin-right: 40px; /* Space between image and text */
    width: 200px; /* Adjust to the desired size */
    height: 200px; /* Ensure height and width are equal to create a circle */
    border-radius: 50%; /* This makes the image circular */
    object-fit: cover; /* Ensures the image fits within the circular shape */
    border: 2px solid #fff; /* Optional: add a border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
  }
  
  .about-text-container {
    max-width: 600px; /* Limit the text container width */
    padding: 20px;
    text-align: inherit; /* Inherit text alignment from the global body direction */
    direction: inherit;
  }
  
  .about-text {
    font-size: 20px;
    text-align: left;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  