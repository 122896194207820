/* Custom Tooltip Styles */
.custom-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.custom-tooltip .tooltip-content {
    display: none; /* Initially hidden */
    position: absolute;
    width: max-content;
    max-width: 220px; /* Adjust based on your preference */
    background-color: #333;
    color: #fff;
    text-align: left;
    direction: ltr; /* Force LTR direction even in RTL languages */
    border-radius: 5px;
    padding: 10px;
    bottom: 125%; /* Position above the text */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    white-space: pre-wrap; /* Ensure line breaks are respected */
    font-size: 12px;
    transition: opacity 0.3s;
    opacity: 0;
}

.custom-tooltip:hover .tooltip-content {
    display: block; /* Show on hover */
    opacity: 1;
}
